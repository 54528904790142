import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { encryptionApi } from './Services/Encryption';
import { marketingApi } from './Services/Marketing';
import { stripeApi } from './Services/Stripe';
import { toastApi } from './Services/Toast';

export const redux = configureStore({
    reducer: {
        [encryptionApi.reducerPath]: encryptionApi.reducer,
        [marketingApi.reducerPath]: marketingApi.reducer,
        [stripeApi.reducerPath]: stripeApi.reducer,
        [toastApi.reducerPath]: toastApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        encryptionApi.middleware,
        marketingApi.middleware,
        stripeApi.middleware,
        toastApi.middleware
    )
});

setupListeners(redux.dispatch);