import { FunctionComponent } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { receiptTheme, centeredSx } from '../../Constants';

const Layout: FunctionComponent<{
  children: React.ReactNode
}> = props => {

  return <Container>
    <ThemeProvider theme={receiptTheme}>
      <Box sx={centeredSx} my={3} py={3}>
        {props.children}
      </Box>
    </ThemeProvider>
  </Container>

}

export default Layout;