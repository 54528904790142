import React, { FunctionComponent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fade from '@mui/material/Fade';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUpdateDiningOptionMutation } from '../../Services/Toast';
import { ReceiptItemList } from '../../Components/Receipt';
import TipModule from '../../Components/Tip';
import AgeGate from '../../Components/AgeGate';
import OptInForm from '../../Components/OptIn';
import Countdown from '../../Components/DeliveryCountdown';
import Spinner from '../../Components/Spinner';
import Error from '../../Components/Error';
import Copyright from '../../Components/Copyright';
import DiningOptions from './DiningOptions';
import CardPay from './CardPay';
import ApplePay from './ApplePay';
import { moneyFormat } from '../../Helpers/Money';
import { ApiResponse, OrderState } from '../../Types/Global';
import { DiningOptions as DiningOptionsType, DeliveryAddress } from '../../Types/Toast';
import { diningTypes, getInitDeliveryAddress } from '../../Constants';
import { transitionDuration } from '../../Constants';

import './Toast.scss';

const Pay: FunctionComponent<{
  order: OrderState,
}> = ({ order }) => {

  const {
    orderCode,
    locatinId: locationId,
    guestId,
    availableDiningType: availableDiningTypes,
    diningType,
    deliveryStatus,
    itemsCostInCents,
    taxInCents,
    applePayEnabled
  } = order;

  const restaurantName = order.restaurantName || "this Location";

  // State Management

  const [totalDue, setTotalDue] = useState(0);
  const [totalDueWithTip, setTotalDueWithTip] = useState(0);
  const [availableDiningOptions, setAvailableDiningOptions] = React.useState<any[]>([]);
  const [selectedDiningOption, setSelectedDiningOption] = React.useState<any>({});
  const [selectedPayTabIndex, setSelectedPayTabIndex] = React.useState<null | number>(null);
  const [deliveryAddress, setDeliveryAddress] = React.useState<DeliveryAddress>(getInitDeliveryAddress());
  const [quotePayload, setQuotePayload] = useState<null | DiningOptionsType>(null);
  const [quoteReady, setQuoteReady] = useState<boolean>(false);
  const [quoteError, setQuoteError] = useState<null | ApiResponse>(null);
  const [displayQuote, setDisplayQuote] = useState<boolean>(false);
  const [newTipAmount, setNewTipAmount] = useState<null | number>(null);
  const [ageVerified, setAgeVerified] = useState<null | boolean>(null);
  const [cardEntry, setCardEntry] = useState(false);
  const payText = "Pay Total: " + moneyFormat(totalDueWithTip);

  // Set Up Dining Option Tabs

  const [updateDiningOption, { isLoading: diningOptionUpdating }] = useUpdateDiningOptionMutation();

  useEffect(() => {
    let foundDiningTypes: any = [];
    if (availableDiningTypes) {
      availableDiningTypes.forEach(diningType => foundDiningTypes.push(diningTypes.find(obj => diningType === obj.id)));
    } else foundDiningTypes = diningTypes.find(obj => obj.id === "dine-in");
    setAvailableDiningOptions(foundDiningTypes);
  }, [availableDiningTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedDiningOption.id) {
      const updatedPayload: DiningOptionsType = {
        orderCode,
        type: selectedDiningOption.id,
        ...deliveryAddress
      };
      let okayToProceed = true;
      if (!!!updatedPayload.type) okayToProceed = false;
      if (selectedDiningOption.requiredFields) {
        selectedDiningOption.requiredFields.forEach((field: keyof DiningOptionsType) => {
          if (field in updatedPayload && !!!updatedPayload[field]) okayToProceed = false;
        });
      }
      setQuotePayload(updatedPayload);
      setQuoteReady(okayToProceed);
    }
  }, [orderCode, diningType, selectedDiningOption, deliveryAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  // Restore Saved Dining Option (on Init Only)

  useEffect(() => {
    if (!!availableDiningOptions.length) {
      if (!displayQuote && !diningOptionUpdating) {
        if (Object.keys(selectedDiningOption).length === 0 && !!diningType) {
          const selected = availableDiningOptions.find(obj => obj.id === diningType);
          if (!!selected) setSelectedDiningOption(selected);
        }
      }
    }
  }, [displayQuote, diningOptionUpdating, availableDiningOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (quoteReady && !!quotePayload) getQuote(quotePayload);
  }, [quoteReady, quotePayload]); // eslint-disable-line react-hooks/exhaustive-deps

  const getQuote = async (quotePayload: DiningOptionsType) => {
    await updateDiningOption(quotePayload)
      .unwrap()
      .then(() => setDisplayQuote(true))
      .catch((err) => setQuoteError(err));
  }

  const refreshQuote = () => {
    setDisplayQuote(false);
    if (!!quotePayload) getQuote(quotePayload);
  }

  const resetQuote = () => {
    setDeliveryAddress(getInitDeliveryAddress());
    setQuoteError(null);
    setQuotePayload(null);
    setDisplayQuote(false);
    setQuoteReady(false);
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    resetQuote();
    setSelectedDiningOption(availableDiningOptions[newValue]);
    setSelectedPayTabIndex(newValue);
  }

  // Apple Pay Availability
  const [applePayAvailable, setApplePayAvailable] = useState<boolean>(false);
  const WindowObject = window as any; // TypeScript Workaround
  useEffect(() => {
    if (typeof WindowObject.ApplePaySession !== 'undefined') {
      if (WindowObject.ApplePaySession.canMakePayments()) {
        if (applePayEnabled) setApplePayAvailable(true);
      }
    }
  }, [WindowObject, totalDueWithTip, applePayEnabled]);

  useEffect(() => { // Activate Age Gate
    if (order.items.some(item => !!item.isAlcohol)) setAgeVerified(false);
  }, [order]);

  useEffect(() => { // Recalculate Total Due
    let newTotalDue = 0;
    let newTotalDueWithTip = 0;
    if (itemsCostInCents) newTotalDue = itemsCostInCents;
    if (taxInCents) newTotalDue += taxInCents;
    // Add Service Charges
    if (order.serviceCharges) order.serviceCharges.forEach(charge => {
      if (!isNaN(charge.amountInCents)) newTotalDue += charge.amountInCents;
    });
    // Deduct Discounts
    if (order.discounts) order.discounts.forEach(discount => {
      if (!isNaN(discount.amountInCents)) newTotalDue -= discount.amountInCents;
    });
    if (newTotalDue < 0) newTotalDue = 0; // No Negative Totals
    newTotalDueWithTip = (newTipAmount !== null) ? (newTotalDue + newTipAmount) : newTotalDue;
    setTotalDue(newTotalDue);
    setTotalDueWithTip(newTotalDueWithTip);
  }, [order, newTipAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCardPay = () => setCardEntry(true);
  const closeCardPay = () => setCardEntry(false);
  const takeTip = (updatedTipAmount: number) => setNewTipAmount(updatedTipAmount);
  const payGateOpen = (ageVerified === null || ageVerified === true);
  const deliveryNotReady = (diningType === 'delivery' && ((!!!deliveryAddress.address) || !quoteReady));

  // Render

  return <React.Fragment>

    {!cardEntry && <Fade in={true} timeout={transitionDuration}>

      <Stack spacing={3}>

        <Typography component="h1" variant="h4" align="center">Pay Bill</Typography>

        <Typography variant="subtitle1" align="center">Your check from {restaurantName}:</Typography>

        {!!order.items.length && <ReceiptItemList items={order.items} />}

        {/* Dining Options Tabs */}

        {diningType && availableDiningOptions.length > 1 && <DiningOptions
          initialDiningType={diningType}
          selectedTabIndex={selectedPayTabIndex}
          handleChangeTab={handleChangeTab}
          handleAddressUpdate={setDeliveryAddress}
          availableDiningOptions={availableDiningOptions}
        />}

        {!!quoteError && <Error errorText={quoteError.data.message} />}

        {!displayQuote
          ? (diningOptionUpdating && <Spinner />)
          : <React.Fragment>

            {/* Subtotal & Total Display */}

            <Accordion className="plain-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }}>
                <Typography variant="body1" align="center" color="primary" width="100%" marginLeft="24px">
                  Total with Taxes & Fees: {moneyFormat(totalDue)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>

                <Typography variant="body1" align="center" color="primary">

                  Subtotal: {moneyFormat(itemsCostInCents)}
                  <br />Tax: {moneyFormat(taxInCents)}

                  {(order && order.serviceCharges) && <React.Fragment>
                    {order.serviceCharges.map((charge, index) => (charge.amountInCents > 0) && <React.Fragment key={index}>
                      <br /><span>
                        {charge.note ? charge.note : `Service Charge`}: {moneyFormat(charge.amountInCents)}
                      </span>
                    </React.Fragment>)}
                  </React.Fragment>}

                  {(order && order.discounts) && <React.Fragment>
                    {order.discounts.map((discount, index) => (discount.amountInCents > 0) && <React.Fragment key={index}>
                      <br /><span style={{ color: "#248f24" }}>
                        {discount.note ? discount.note : `Discount`}: -{moneyFormat(discount.amountInCents)}
                      </span>
                    </React.Fragment>)}
                  </React.Fragment>}

                </Typography>

              </AccordionDetails>
            </Accordion>

            {deliveryStatus && <Countdown
              eta={deliveryStatus.estimatedTime}
              countDownTo={deliveryStatus.quoteExiresAtUtc}
              resetCounter={refreshQuote}
            />}

            <Stack spacing={1}>

              <Stack spacing={0}>
                <Typography component="h4" variant="subtitle1" align="center">Add a Tip</Typography>
                {selectedDiningOption.id === "delivery" &&
                  <Typography variant="caption" align="center">
                    Your tip will go to the delivery driver.
                  </Typography>}
              </Stack>

              <TipModule itemsCostInCents={itemsCostInCents} handleUpdate={takeTip} />

              {ageVerified !== null && <Box pt={2}>
                <AgeGate handleResponse={setAgeVerified} />
              </Box>}

              {(!!locationId && !!guestId) && <Box pt={2} sx={{ maxWidth: "700px" }}>
                <OptInForm locationId={locationId} guestId={guestId} />
              </Box>}

              <Box pt={2} style={{ width: "100%" }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={(!payGateOpen || deliveryNotReady)}
                  startIcon={<CreditCardIcon />}
                  onClick={handleCardPay}>
                  {payText}
                </Button>
              </Box>

              {applePayAvailable && <Box>
                <ApplePay
                  orderCode={orderCode}
                  totalInCents={totalDueWithTip}
                  tipInCents={newTipAmount ? newTipAmount : 0}
                  locationName={restaurantName}
                  disabled={(!payGateOpen || deliveryNotReady)}
                />
              </Box>}

            </Stack>

          </React.Fragment>}

        <Copyright />

      </Stack>

    </Fade>}

    {cardEntry && <Fade in={true} timeout={transitionDuration}>
      <Stack spacing={3}>
        <CardPay
          orderCode={order.orderCode}
          totalInCents={totalDueWithTip}
          tipInCents={newTipAmount ? newTipAmount : 0}
          handleCancel={closeCardPay}
        />
        <Copyright />
      </Stack>
    </Fade>}

  </React.Fragment>

}

export default Pay;