import React, { FunctionComponent, useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useGetOptinTextQuery, useGetOptinStatusQuery, useUpdateOptinStatusMutation } from '../Services/Marketing';

const OptInForm: FunctionComponent<{ locationId: string, guestId: string, }> = ({ locationId, guestId }) => {

  const [optedIn, setOptedIn] = useState<boolean>(false);

  const { data: statusText = null } = useGetOptinTextQuery(locationId);
  const { data: statusData = null, isLoading: statusLoading } = useGetOptinStatusQuery({ guestId, locationId });
  const [updateOptinStatus, { isLoading: optinUpdating }] = useUpdateOptinStatusMutation();

  useEffect(() => {
    if (statusData && !!locationId) {
      const foundStatus = statusData.optinStatues.find(status => status.locationId === locationId);
      if (foundStatus) setOptedIn(!!foundStatus.optedIn);
    }
  }, [statusData, locationId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateOptinStatus({ locationId, guestId, optedIn: event.target.checked });
  };

  return <React.Fragment>
    {statusText && <FormControlLabel
      disabled={(statusLoading || optinUpdating)}
      label={<Typography className={(statusLoading || optinUpdating) ? "disabled" : ""} variant="body2">
        {!!statusText.optinMessage ? statusText.optinMessage : "Opt in for promotional offers."}
      </Typography>}
      control={
        <Checkbox
          sx={{ marginRight: "8px" }}
          checked={optedIn}
          onChange={handleChange}
        />
      }
    />}
  </React.Fragment>

}

export default OptInForm;
