import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { DiningType } from '../../Types/Global';
import { DeliveryAddress } from '../../Types/Toast';
import { addressForToast } from '../../Helpers/Places';

import './Toast.scss';

const { REACT_APP_GOOGLE_API_KEY } = process.env;

const PayOptionsPanel: React.FunctionComponent<{
  children?: React.ReactNode;
  index: number;
  value: number;
}> = ({ children, value, index, ...other }) => {

  return <div role="tabpanel" hidden={value !== index} id={`payment-tab-${index}`} {...other}>
    {value === index && <Box sx={{ p: 0 }}>
      <Box>{children}</Box>
    </Box>}
  </div>;

}

const DeliveryLocation: React.FunctionComponent<{ handleResponse: (address: DeliveryAddress) => void }> = ({ handleResponse }) => {

  const [value, setValue] = useState<any>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [parsedAddress, setParsedAddress] = useState<any>({});
  const [instructions, setInstructions] = useState<string>('');
  const [addressSaved, setAddressSaved] = useState<boolean>(false);

  useEffect(() => {
    if (!!value && value.label) geocodeByAddress(value.label).then(results => setParsedAddress(addressForToast(results[0])));
  }, [value]);

  useEffect(() => setAddressSaved(false), [parsedAddress, firstName, lastName, businessName, instructions]);

  const saveAddress = () => {
    handleResponse({
      ...parsedAddress,
      contactName: firstName,
      contactLastName: lastName,
      addressName: businessName,
      instructions
    });
    setAddressSaved(true);
  }

  return <Box mb={2}>
    <Stack spacing={1}>

      <Typography component="h4" variant="subtitle1" align="center">Delivery Address:</Typography>

      <Stack direction="row" spacing={1}>
        <Box className="contact_name">
          <TextField
            required
            fullWidth
            size="small"
            label="First Name"
            value={firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(event.target.value);
            }}
          />
        </Box>
        <Box className="contact_name">
          <TextField
            fullWidth
            size="small"
            label="Last Name"
            value={lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(event.target.value);
            }}
          />
        </Box>
      </Stack>

      <Box className="location_selector">
        {!!REACT_APP_GOOGLE_API_KEY && <GooglePlacesAutocomplete
          apiKey={REACT_APP_GOOGLE_API_KEY}
          selectProps={{
            required: true,
            value,
            onChange: setValue,
            placeholder: 'Your Address',
          }}
          autocompletionRequest={{ componentRestrictions: { country: ['us'] } }}
        />}
      </Box>

      <Box className="location_name">
        <TextField
          fullWidth
          size="small"
          label="Business/Building Name"
          value={businessName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setBusinessName(event.target.value);
          }}
        />
      </Box>

      <Box className="location_instructions">
        <TextField
          fullWidth
          size="small"
          label="Delivery Instructions"
          value={instructions}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInstructions(event.target.value);
          }}
        />
      </Box>

      <Box>
        <Button
          fullWidth
          variant="outlined"
          color={addressSaved ? "success" : "primary"}
          size="large"
          onClick={saveAddress}
          disabled={(!!!firstName || !parsedAddress || !!!parsedAddress.address)}
        >{addressSaved ? "Saved" : "Next"}
        </Button>
      </Box>

    </Stack>
  </Box>

}

const DiningOptions: React.FunctionComponent<{
  initialDiningType: DiningType,
  selectedTabIndex: null | number,
  handleChangeTab: (event: React.SyntheticEvent, newValue: number) => void,
  handleAddressUpdate: (deliveryAddress: any) => void,
  availableDiningOptions: any[]
}> = ({ initialDiningType, selectedTabIndex, handleChangeTab, handleAddressUpdate, availableDiningOptions }) => {

  let activeTabIndex = availableDiningOptions.findIndex(obj => obj.id === initialDiningType) || 0;
  if (selectedTabIndex !== null) activeTabIndex = selectedTabIndex;

  return <React.Fragment>

    {availableDiningOptions.length > 1 && <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={activeTabIndex} onChange={handleChangeTab} className="delivery-tabs" centered>
        {availableDiningOptions.map((diningOptionObject: any, index) =>
          <Tab
            key={index}
            id={`payment-tab-${index}`}
            label={diningOptionObject.displayText}
            icon={diningOptionObject.displayIcon}
            iconPosition="start"
          />
        )}
      </Tabs>
    </Box>}

    {availableDiningOptions.map((diningOptionObject: any, index) =>
      <PayOptionsPanel key={index} value={activeTabIndex} index={index}>

        {diningOptionObject.id === "delivery" &&
          <DeliveryLocation handleResponse={handleAddressUpdate} />
        }

      </PayOptionsPanel>
    )}

  </React.Fragment>

}

export default DiningOptions;