import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { OptinText, OptinStatusRequest, OptinStatus, LocationOptinStatus } from '../Types/Global';

const reducerPath = "marketingApi";
const { REACT_APP_MARKETING_SVC } = process.env;

export const marketingApi = createApi({
    reducerPath,
    tagTypes: ["Status"],
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_MARKETING_SVC
    }),
    endpoints: (builder) => ({
        getOptinText: builder.query<OptinText, string>({
            query: (locationId) => `/api/Location/${locationId}`
        }),
        getOptinStatus: builder.query<OptinStatus, OptinStatusRequest>({
            providesTags: (res, err, { guestId }) => [{ type: 'Status', id: guestId }],
            query: ({ guestId, locationId }) => `/api/Guest/${guestId}/${locationId}`
        }),
        updateOptinStatus: builder.mutation<OptinStatus, LocationOptinStatus>({
            invalidatesTags: (res, err, { guestId }) => [{ type: 'Status', id: guestId }],
            query: ({ guestId, locationId, optedIn }) => ({
                url: `/api/Guest/${guestId}/${locationId}?optin=${optedIn}`,
                method: 'POST',
            })
        })
    })
});

export const {
    useGetOptinTextQuery,
    useGetOptinStatusQuery,
    useUpdateOptinStatusMutation
} = marketingApi;