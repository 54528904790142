import { FunctionComponent, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import NumberFormat from 'react-number-format';
import { ReceiptItemList } from '../../Components/Receipt';
import TipModule from '../../Components/Tip';
import Copyright from '../../Components/Copyright';
import { useUpdateOrderTipMutation } from '../../Services/Stripe';
import { moneyFormat } from '../../Helpers/Money';
import { OrderState } from '../../Types/Global';
import { transitionDuration } from '../../Constants';

const { REACT_APP_STRIPE_KEY } = process.env;

const Pay: FunctionComponent<{
  order: OrderState,
}> = ({ order }) => {

  // Order Variables

  const { orderCode, itemsCostInCents, taxInCents, stripeAccount } = order;
  const restaurantName = order.restaurantName || "this Location";
  const itemsCostInDollars = moneyFormat(itemsCostInCents);
  const taxInDollars = moneyFormat(taxInCents);

  // State Management

  const [totalDue, setTotalDue] = useState(0);
  const [newTipAmount, setNewTipAmount] = useState<null | number>(null);
  const payText = "Pay Total: " + moneyFormat(totalDue);
  const [updateOrderTip, { isLoading: tipUpdating }] = useUpdateOrderTipMutation();

  useEffect(() => { // Recalculate Total Due
    let newTotalDue = 0;
    if (itemsCostInCents) newTotalDue += itemsCostInCents;
    if (taxInCents) newTotalDue += taxInCents;
    if (newTipAmount !== null) newTotalDue += newTipAmount;
    if (newTotalDue < 0) newTotalDue = 0; // No Negative Totals
    setTotalDue(newTotalDue);
  }, [order, newTipAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const payReady = (order && !!orderCode && !!stripeAccount && newTipAmount !== null);

  const takeTip = (updatedTipAmount: number) => setNewTipAmount(updatedTipAmount);

  const handlePay = async () => {
    if (payReady && newTipAmount !== null) {
      const { stripeSessionId: sessionId } = await updateOrderTip({ orderCode, tipInCents: newTipAmount }).unwrap();
      if (!!sessionId && !!stripeAccount) {
        const stripe = await loadStripe(`${REACT_APP_STRIPE_KEY}`, { stripeAccount });
        if (stripe) await stripe.redirectToCheckout({ sessionId });
      }
    }
  }

  return <Fade in={true} timeout={transitionDuration}>
    <Stack spacing={3}>

      <Typography component="h1" variant="h4" align="center">Pay Bill</Typography>

      <Typography variant="subtitle1" align="center">Your check from {restaurantName}:</Typography>

      <ReceiptItemList items={order.items} />

      {/* Subtotal Display */}

      <Typography variant="body1" align="center" color="primary">
        Subtotal: <NumberFormat value={itemsCostInDollars} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
        Tax: <NumberFormat value={taxInDollars} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'} />
      </Typography>

      {/* Tip Module */}

      <Stack spacing={1}>
        <Typography component="h4" variant="subtitle1" align="center">Add a Tip</Typography>
        <TipModule itemsCostInCents={itemsCostInCents} handleUpdate={takeTip} />
      </Stack>

      {/* Pay Button */}

      <Box py={3} style={{ width: "100%" }}>
        <Button fullWidth variant="contained" color="primary" size="large" onClick={handlePay} disabled={(!payReady || tipUpdating)}>
          {payText}
        </Button>
      </Box>

      <Copyright />

    </Stack>
  </Fade>

}

export default Pay;