import React from 'react';
import ToastLayout from './Layout';

export default function Hello() {

  return <ToastLayout>
    <React.Fragment>
      <p>Hi, I'm the Toast flow!</p>
    </React.Fragment>
  </ToastLayout>

}