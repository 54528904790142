import React, { FunctionComponent } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { ReceiptItemList } from '../../Components/Receipt';
import Copyright from '../../Components/Copyright';
import { moneyFormat } from '../../Helpers/Money';
import { getDeliveryTimeFromDateString } from '../../Helpers/Time';
import { OrderState } from '../../Types/Global';
import { transitionDuration } from '../../Constants';

const Paid: FunctionComponent<{
  order: OrderState,
}> = ({ order }) => {

  const { subTotalInCents, taxInCents } = order;
  const restaurantName = order.restaurantName || "this Location";
  const tipInCents = order.tipInCents;
  const totalDueInCents = order.totalDueInCents;
  const tipPercentage = Math.round(tipInCents * 100 / subTotalInCents);

  return <Fade in={true} timeout={transitionDuration}>
    <Stack spacing={3}>

      <Typography component="h1" variant="h4" align="center">Payment Successful</Typography>
      <Typography variant="subtitle1" align="center">Your order from {restaurantName} has been submitted!</Typography>

      {!!order.items.length && <ReceiptItemList items={order.items} />}

      <Typography variant="body1" align="center" color="primary">

        Subtotal: {moneyFormat(subTotalInCents)}
        <br />Tax: {moneyFormat(taxInCents)}

        {(order && order.serviceCharges) && <React.Fragment>
          {order.serviceCharges.map((charge, index) => <React.Fragment key={index}>
            <br /><span>
              {charge.note ? charge.note : `Service Charge`}: {moneyFormat(charge.amountInCents)}
            </span>
          </React.Fragment>)}
        </React.Fragment>}

        {!!tipInCents && <React.Fragment><br /><span>
          {`Tip: ${moneyFormat(tipInCents)} (${tipPercentage}%)`}
        </span></React.Fragment>}

        {(order && order.discounts) && <React.Fragment>
          {order.discounts.map((discount, index) => <React.Fragment key={index}>
            <br /><span style={{ color: "#248f24" }}>
              {discount.note ? discount.note : `Discount`}: -{moneyFormat(discount.amountInCents)}
            </span>
          </React.Fragment>)}
        </React.Fragment>}

      </Typography>

      <Typography variant="body1" align="center" color="green">
        Total Paid: {totalDueInCents && moneyFormat(totalDueInCents)}
      </Typography>

      {(order && order.deliveryStatus) && <Stack spacing={2}>
        <Typography variant="body1" align="center" color="inherit">
          Estimated Delivery: {getDeliveryTimeFromDateString(order.deliveryStatus.estimatedTime)}
        </Typography>
        <Button target="_blank" href={order.deliveryStatus.trackingUrl} variant="contained" color="primary" startIcon={<DeliveryDiningIcon />}>
          Track your Delivery
        </Button>
      </Stack>}

      <Stack spacing={0}>
        <Typography variant="subtitle1" align="center">Thank you! Please come again!</Typography>
        <Typography variant="caption" align="center">Statement will show charge from OhWaiter.</Typography>
      </Stack>

      <Copyright />
    </Stack>
  </Fade >

}

export default Paid;