import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Spinner from './Spinner';

type LoadingProps = {
  transitionDuration: number | null;
  headerText?: string | null;
};

const Loading: React.FC<LoadingProps> = ({ transitionDuration, headerText }) => {
  const td = !!transitionDuration ? transitionDuration : 0;
  return <Fade in={true} timeout={td}>
    <Stack spacing={3}>
      {headerText && <Typography component="h1" variant="h4" align="center">{headerText}</Typography>}
      <Spinner />
    </Stack>
  </Fade>
}

export default Loading;