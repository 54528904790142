import React, { FunctionComponent } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import { moneyFormat } from '../Helpers/Money';
import { OrderItem, OrderModifier } from '../Types/Global';
import { receiptListSx, receiptListItemSx } from '../Constants';

const nestingPad = 0.5;

export const ReceiptItemList: FunctionComponent<{
  items: OrderItem[],
}> = props => {
  const { items } = props;
  return <List sx={receiptListSx}>
    {items.map(i => {
      const topItemString: string = i.name + (i.quantity > 1 ? ` (${i.quantity})` : "");
      return <React.Fragment key={i.id}>
        <ListItem disablePadding>
          <ListItemButton dense sx={receiptListItemSx}>
            <Typography align="left" variant="body1" pr={8}>{topItemString}</Typography>
            <Typography align="right" variant="body2">
              {!!i.costInCents && <NumberFormat value={moneyFormat(i.costInCents * i.quantity)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
            </Typography>
          </ListItemButton>
        </ListItem>
        {!!i.modifications && <ReceiptItemModifierList quantity={i.quantity} modifications={i.modifications} padding={nestingPad} />}
      </React.Fragment>
    })}
  </List>
}

export const ReceiptItemModifierList: FunctionComponent<{
  padding: number,
  quantity: number,
  modifications: OrderModifier[],
}> = props => {
  const { padding, quantity = 1, modifications } = props;
  return <List disablePadding>
    {modifications.map(m => {
      const modItemString: string = m.name + (quantity > 1 ? ` (${quantity})` : "");
      return <React.Fragment key={m.id}>
        <ListItem disablePadding>
          <ListItemButton dense sx={receiptListItemSx}>
            <Typography align="left" variant="caption" pl={props.padding} pr={8}>+ {modItemString}</Typography>
            <Typography align="right" variant="caption">
              {!!m.costInCents && <NumberFormat value={moneyFormat(m.costInCents * quantity)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
            </Typography>
          </ListItemButton>
        </ListItem>
        {!!m.modifications && <ReceiptItemModifierList quantity={quantity} modifications={m.modifications} padding={padding + nestingPad} />}
      </React.Fragment>
    })}
  </List>
}