import { createTheme } from '@mui/material/styles';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

export const defaultCardHolderName = "OhWaiter Guest";
export const defaultCardHolderEmail = "support@ohwaiter.com";
export const getInitDeliveryAddress = () => {
    return Object.assign({}, {
        contactName: defaultCardHolderName,
        addressName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        instructions: ''
    })
}

export const tipPercentages = [15, 20, 25];
export const zeroDollarTips = [0, 100, 200];
export const defaultTipPercent = 20;

export const diningTypes = [
    {
        id: "dinein",
        displayText: "Dine In",
        displayIcon: <TableRestaurantIcon />,
        requiredFields: null
    },
    {
        id: "pickup",
        displayText: "Pickup",
        displayIcon: <TakeoutDiningIcon />,
        requiredFields: null
    },
    {
        id: "delivery",
        displayText: "Delivery",
        displayIcon: <DeliveryDiningIcon />,
        requiredFields: ["address", "city", "state", "zip"]
    }
];

export const transitionDuration = 800;

export const receiptTheme = createTheme({
    typography: {
        h4: {
            fontWeight: "bold"
        },
        subtitle1: {
            fontWeight: "bold"
        },
        body1: {
            fontWeight: "bold"
        },
        button: {
            fontWeight: "bold"
        }
    },
});

export const receiptListSx = {
    width: "100%",
    border: "1px solid #CCC",
    borderRadius: "4px",
};

export const receiptListItemSx = {
    display: "flex",
    justifyContent: "space-between",
    cursor: "default"
};

export const centeredSx = {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: 0
};