import React, { FunctionComponent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

const AgeGate: FunctionComponent<{ handleResponse: (toggle: boolean) => void }> = ({ handleResponse }) => {

  const [ageVerified, setAgeVerified] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const shouldBe = dayjs().subtract(21, 'year');
    const wasBorn = dayjs(selectedDate);
    const secsDiff = shouldBe.diff(wasBorn, 'seconds');
    if (!isNaN(secsDiff) && secsDiff >= 0) {
      setAgeVerified(true);
      setErrorMessage(null);
    } else {
      setAgeVerified(false);
      if (isNaN(secsDiff)) setErrorMessage("Please enter a valid date.");
      if (!isNaN(secsDiff) && secsDiff < 0) setErrorMessage("Looks like you're not 21 years old. Please start your order over, or correct your DOB.");
    }
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => handleResponse(ageVerified), [ageVerified]); // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment>

    <Typography variant="body2" align="center" mb={1}>Your order contains alcohol. Enter your birth date to confirm you are 21+ and be prepared to show valid ID.</Typography>

    <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          value={selectedDate}
          onChange={(selectedDate) => setSelectedDate(selectedDate)}
        />
      </LocalizationProvider>
    </Box>

    {errorMessage && <Typography variant="body2" align="center" color="error" mt={1}>{errorMessage}</Typography>}

  </React.Fragment>

}

export default AgeGate;