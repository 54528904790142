import React, { FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLazyGetApplePayMerchantSessionQuery } from '../../Services/Toast';
import { useSubmitApplePayPaymentMutation } from '../../Services/Toast';
import { CreditCardPayment } from '../../Types/Toast';

import './Apple.css';
let ApplePaySession: null | any = null;

const ApplePay: FunctionComponent<{
  orderCode: string,
  locationName: string,
  totalInCents: number,
  tipInCents: number,
  disabled?: boolean
}> = props => {

  const { orderCode, locationName, totalInCents, tipInCents, disabled } = props;
  const WindowObject = window as any; // TypeScript Workaround
  const [getApplePayMerchantSession] = useLazyGetApplePayMerchantSessionQuery();
  const [submitApplePayment] = useSubmitApplePayPaymentMutation();

  const applePayPUID = uuidv4();
  const appDataEncoded = Buffer.from(`{ "puid": "${applePayPUID}" }`).toString('base64');

  const applePaySuccess = () => ApplePaySession.completePayment("STATUS_SUCCESS");
  const applePayFail = () => ApplePaySession.completePayment("STATUS_FAILURE");

  function applePayCreateRequest(totalInCents: number) {
    const request = {
      "countryCode": "US",
      "currencyCode": "USD",
      "merchantCapabilities": [
        "supports3DS"
      ],
      "supportedNetworks": [
        "visa",
        "masterCard",
        "amex",
        "discover"
      ],
      "requiredBillingContactFields": [
        "name",
        "postalAddress"
      ],
      "total": {
        "label": locationName,
        "type": "final",
        "amount": (totalInCents / 100).toString()
      },
      "applicationData": appDataEncoded
    }
    return request;
  }

  const validateMerchant = (event: any) => {
    getApplePayMerchantSession(orderCode)
      .unwrap()
      .then(result => ApplePaySession.completeMerchantValidation(result))
      .catch(error => console.error(error));
  }

  const completeAuthorization = (event: any) => {
    if (event && event.payment) {
      const { billingContact } = event.payment;
      const paymentEncoded = Buffer.from(JSON.stringify(event.payment)).toString("base64");
      let name = `${billingContact.givenName} ${billingContact.familyName}`.trim();
      if (name === "") name = "OhWaiter Guest";
      const payload: CreditCardPayment = {
        orderCode,
        tipInCents,
        creditCardData: paymentEncoded,
        cardFirst6: "",
        cardLast4: "",
        applicationData: appDataEncoded,
        name,
        email: "applepay@ohwaiter.com",
        postalCode: billingContact.postalCode || "",
        address1: billingContact.addressLines[0] || "",
        city: billingContact.locality || "",
        state: billingContact.administrativeArea || ""
      }
      submitApplePayment(payload)
        .unwrap()
        .then((result) => applePaySuccess())
        .catch((error) => {
          console.error(error);
          applePayFail();
        });
    } else applePayFail();
  }

  const applePayCreateSession = () => {
    const applePayRequest = applePayCreateRequest(totalInCents);
    ApplePaySession = new WindowObject.ApplePaySession(3, applePayRequest);
    ApplePaySession.puid = applePayPUID;
    ApplePaySession.total = totalInCents / 100;
    ApplePaySession.applicationData = appDataEncoded;
    ApplePaySession.onvalidatemerchant = validateMerchant;
    ApplePaySession.onpaymentauthorized = completeAuthorization;
    ApplePaySession.begin();
  };

  const disabledClass = !!disabled ? "disabled" : "";

  return <React.Fragment>{applePayPUID &&
    <div className={`apple-pay-button ${disabledClass}`} onClick={applePayCreateSession} />
  }</React.Fragment>

}

export default ApplePay;