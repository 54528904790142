import React from 'react';
import StripeLayout from './Layout';

export default function Hello() {

  return <StripeLayout>
    <React.Fragment>
      <p>Hi, I'm the Stripe flow!</p>
    </React.Fragment>
  </StripeLayout>

}