import { FunctionComponent } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { receiptTheme, centeredSx } from '../Constants';

const Home: FunctionComponent = () => {

  return <Container>
    <ThemeProvider theme={receiptTheme}>
      <Box sx={centeredSx} py={3}>
        OhWaiter Payment Platform
      </Box>
    </ThemeProvider>
  </Container>

}

export default Home;