//import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Copyright() {

  return <Box mt={6}>
    <Typography variant="body2" color="textSecondary" align="center">{`Powered by `}
      <Link color="inherit" href="https://www.ohwaiter.com/">OhWaiter</Link>
    </Typography>
  </Box>

}