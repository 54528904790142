import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';

const Error: React.FunctionComponent<{ errorText: string; }> = ({ errorText }) => {

  return <Stack spacing={2}>
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <ErrorIcon fontSize="large" color="error" />
    </Box>
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <Typography align="center" variant="button" color="error">{errorText}</Typography>
    </Box>
  </Stack>

}

export default Error;