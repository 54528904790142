import { FunctionComponent } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Copyright from '../../Components/Copyright';
import { OrderState } from '../../Types/Global';
import { transitionDuration } from '../../Constants';

const Cancelled: FunctionComponent<{
  order: OrderState,
}> = props => {
  return <Fade in={true} timeout={transitionDuration}>
    <Stack spacing={3}>
      <Typography component="h1" variant="h4" align="center">Payment Cancelled</Typography>
      <Typography variant="subtitle1" align="center">Please ask your server to send another check.</Typography>
      <Copyright />
    </Stack>
  </Fade>
}

export default Cancelled;