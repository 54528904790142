import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const reducerPath = "encryptionApi";
const { REACT_APP_ENCRYPTION_SVC } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_ENCRYPTION_SVC,
});

export const encryptionApi = createApi({
    reducerPath,
    baseQuery,
    endpoints: (builder) => ({
        getEncryptedData: builder.query<string, any>({
            query: (body) => ({
                method: 'POST',
                responseHandler: 'text',
                url: `/api/Encrypt`,
                body
            })
        })
    })
});

export const {
    useGetEncryptedDataQuery,
    useLazyGetEncryptedDataQuery
} = encryptionApi;