import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../Components/Loading';
import StripeLayout from './Layout';
import { useGetOrderByCodeQuery, useOrderCancelMutation } from '../../Services/Stripe';
import { transitionDuration } from '../../Constants';

export default function Success() {

  const navigate = useNavigate();
  const { orderCode: orderParam, sessionId: sessionParam } = useParams();
  const [orderCode, setOrderCode] = useState("");
  const [sessionId, setSessionId] = useState("");
  useEffect(() => { if (!!orderParam) setOrderCode(orderParam); }, [orderParam]);
  useEffect(() => { if (!!sessionParam) setSessionId(sessionParam); }, [sessionParam]);

  const { data: orderData = null, isLoading: orderLoading } = useGetOrderByCodeQuery(orderCode, { skip: (!!!orderCode) });
  const [orderCancel] = useOrderCancelMutation();

  useEffect(() => { if (sessionId && orderData && orderData.status) completeCancellation(); }, [orderData, sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const completeCancellation = async () => {
    if (orderData && orderData.status === "open") await orderCancel(sessionId);
    navigate(`/stripe/order/${orderCode}`);
  };

  return <StripeLayout>
    {(orderLoading)
      ? <Loading headerText="Loading Order" transitionDuration={transitionDuration} />
      : <Loading headerText="Processing" transitionDuration={transitionDuration} />
    }
  </StripeLayout>

}