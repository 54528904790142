import React, { FunctionComponent } from 'react';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import { ReceiptItemList } from '../../Components/Receipt';
import Copyright from '../../Components/Copyright';
import { moneyFormat } from '../../Helpers/Money';
import { OrderState } from '../../Types/Global';
import { transitionDuration } from '../../Constants';

const Paid: FunctionComponent<{
  order: OrderState,
}> = props => {

  const { order } = props;
  const restaurantName = order.restaurantName || "this Location";
  const itemsCostInCents = order.itemsCostInCents;
  const itemsCostInDollars = moneyFormat(itemsCostInCents);
  const taxInCents = order.taxInCents;
  const taxInDollars = moneyFormat(taxInCents);
  const tipInCents = order.tipInCents;
  const tipInDollars = moneyFormat(tipInCents);
  const tipPercentage = Math.round(tipInCents * 100 / itemsCostInCents);
  const totalDueInDollars = moneyFormat(order.totalDueInCents);

  return <Fade in={true} timeout={transitionDuration}>
    <Stack spacing={3}>

      <Typography component="h1" variant="h4" align="center">Payment Successful</Typography>
      <Typography variant="subtitle1" align="center">Your order from {restaurantName} has been submitted!</Typography>

      <ReceiptItemList items={order.items} />

      <Typography variant="subtitle2" align="center" color="green">
        Subtotal: <NumberFormat value={itemsCostInDollars} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
        Tax: <NumberFormat value={taxInDollars} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'} /><br />
        Tip: <NumberFormat value={tipInDollars} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'} /> ({tipPercentage}%)<br />
      </Typography>

      <Typography variant="body1" align="center" color="green">
        Total Paid: <NumberFormat value={totalDueInDollars} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'} />
      </Typography>

      <Stack spacing={0}>
        <Typography variant="subtitle1" align="center">Thank you! Please come again!</Typography>
        <Typography variant="caption" align="center">Statement will show charge from OhWaiter.</Typography>
      </Stack>

      <Copyright />
    </Stack>
  </Fade>

}

export default Paid;