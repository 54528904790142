import React, { FunctionComponent } from 'react';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Countdown from 'react-countdown';
import { getDeliveryTimeFromDateString } from '../Helpers/Time';

const DeliveryCountdown: FunctionComponent<{
  eta: string,
  countDownTo: string,
  resetCounter: () => void,
}> = ({ eta, countDownTo, resetCounter }) => {

  return <Stack spacing={0}>
    <Countdown
      date={moment(countDownTo).format()}
      renderer={({ minutes: mm, seconds: ss }) => {
        return (mm > 0 && ss > 0)
          ? <React.Fragment>
            <Typography variant="body1" align="center" color="inherit">
              Estimated Delivery: {getDeliveryTimeFromDateString(eta)}
            </Typography>
            <Typography variant="body1" align="center" style={{ color: "#248f24" }}>
              Time Left to Complete Order: {mm}:{ss.toString().padStart(2, "0")}
            </Typography>
          </React.Fragment>
          : <React.Fragment>
            <Typography mb={1} variant="body1" align="center" color="error">
              Delivery Quote Expired
            </Typography>
            <Button variant="outlined" color="error" size="medium" onClick={resetCounter}>
              Refresh
            </Button>
          </React.Fragment>
      }}
    />
  </Stack>

}

export default DeliveryCountdown;