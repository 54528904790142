import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';

// Stripe Flow Pages
import HelloStripe from './Flows/Stripe/Hello';
import StripeOrder from './Flows/Stripe/Order';
import { Check as StripeCheck } from './Flows/Stripe/Check';
import StripeSuccess from './Flows/Stripe/Success';
import StripeCancel from './Flows/Stripe/Cancel';

// Toast Flow Pages
import HelloToast from './Flows/Toast/Hello';
import ToastOrder from './Flows/Toast/Order';

export default function App() {

  // TODO: Can group different <Routes> sections by flow to clean this file up.

  return <Router>
    <Routes>

      <Route path="/" element={<Home />} />

      <Route path="/toast" element={<HelloToast />} />
      <Route path="/toast/order/:orderCode" element={<ToastOrder />} />

      <Route path="/stripe" element={<HelloStripe />} />
      <Route path="/stripe/check/:checkId" element={<StripeCheck />} />
      <Route path="/stripe/order/:orderCode" element={<StripeOrder />} />
      <Route path="/stripe/order_success/:orderCode/:sessionId" element={<StripeSuccess />} />
      <Route path="/stripe/order_cancel/:orderCode/:sessionId" element={<StripeCancel />} />

    </Routes>
  </Router>

}