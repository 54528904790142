import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ApiResponse, OrderState } from '../Types/Global';
import type { CreditCardPayment, DiningOptions } from '../Types/Toast';
import type { ApplePayMerchantSession } from '../Types/Apple';

const reducerPath = "toastApi";
const { REACT_APP_TOAST_API_URL } = process.env;

export const toastApi = createApi({
    reducerPath,
    tagTypes: ["Order"],
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_TOAST_API_URL
    }),
    endpoints: (builder) => ({
        getOrderByCode: builder.query<OrderState, string>({
            providesTags: (res, err, orderCode) => [{ type: 'Order', id: orderCode }],
            query: (orderCode) => `/api/order/${orderCode}`,
            transformResponse: (res: ApiResponse) => res.data
        }),
        updateDiningOption: builder.mutation<OrderState, DiningOptions>({
            invalidatesTags: (res, err, { orderCode }) => [{ type: 'Order', id: orderCode }],
            query: (diningOption) => ({
                url: `/api/order/${diningOption.orderCode}/update_dining_option`,
                method: 'POST',
                body: diningOption
            })
        }),
        getApplePayMerchantSession: builder.query<ApplePayMerchantSession, string>({
            query: (orderCode) => `/api/order/${orderCode}/apple_pay_session`,
            transformResponse: (res: ApiResponse) => res.data
        }),
        submitCreditCardPayment: builder.mutation<OrderState, CreditCardPayment>({
            invalidatesTags: (res, err, { orderCode }) => [{ type: 'Order', id: orderCode }],
            query: (payment) => ({
                url: `/api/order/${payment.orderCode}/submit_to_pos`,
                method: 'POST',
                body: payment
            })
        }),
        submitApplePayPayment: builder.mutation<OrderState, CreditCardPayment>({
            invalidatesTags: (res, err, { orderCode }) => [{ type: 'Order', id: orderCode }],
            query: (payment) => ({
                url: `/api/order/${payment.orderCode}/submit_to_pos_apple_pay`,
                method: 'POST',
                body: payment
            })
        })
    })
});

export const {
    useGetOrderByCodeQuery,
    useUpdateDiningOptionMutation,
    useGetApplePayMerchantSessionQuery,
    useLazyGetApplePayMerchantSessionQuery,
    useSubmitCreditCardPaymentMutation,
    useSubmitApplePayPaymentMutation
} = toastApi;