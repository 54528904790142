import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { moneyFormat } from '../Helpers/Money';
import { tipPercentages, defaultTipPercent, zeroDollarTips } from '../Constants';

const Tip: FunctionComponent<{
  itemsCostInCents: number,
  handleUpdate: (newTipAmount: number) => void
}> = ({ itemsCostInCents, handleUpdate }) => {

  const inputRef: any = useRef();
  const [newTipAmount, setNewTipAmount] = useState<null | number>(null);
  const [customTipEntry, setCustomTipEntry] = useState(false);
  const [customTipAmount, setCustomTipAmount] = useState('');

  useEffect(() => { // Set Default Tip
    const defaultTipInCents = Math.round((itemsCostInCents / 100) * defaultTipPercent);
    if (newTipAmount === null) setNewTipAmount(defaultTipInCents);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { // Live Updating
    if (newTipAmount !== null) handleUpdate(newTipAmount);
  }, [newTipAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { // Listen to Custom Tip Entry
    if (customTipAmount) {
      const newAmount = Math.round(parseFloat(customTipAmount) * 100);
      setNewTipAmount(newAmount);
    }
  }, [customTipAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  let tipOptionsInCents: number[] = [];

  tipPercentages.map(p => tipOptionsInCents.push(Math.round((itemsCostInCents / 100) * p)));
  const customTipPercentage = (newTipAmount !== null) ? Math.round(newTipAmount * 100 / itemsCostInCents) : 0;
  let customTipSet = (newTipAmount !== null && !tipOptionsInCents.includes(newTipAmount));
  if (itemsCostInCents <= 0) {
    customTipSet = (newTipAmount !== null && !zeroDollarTips.includes(newTipAmount));
  }

  const tipButtonStyle = {
    display: "block",
    textAlign: "center"
  };

  const toggleCustomTip = () => {
    if (newTipAmount !== null) setCustomTipAmount(moneyFormat(newTipAmount, false) as string);
    setCustomTipEntry(!customTipEntry);
  }

  const confirmCustomTip = () => {
    if (!!!customTipAmount) setCustomTipAmount("0.00");
    setCustomTipEntry(false);
  }

  const changeCustomTip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]+?\.?[0-9]{0,2}$/; // Currency Validation (Progressive)
    if (e.target.value === "" || regex.test(e.target.value)) {
      setCustomTipAmount(e.target.value.replace(/[^\d.]/g, ''));
    }
  }

  const clearCustomTip = () => {
    setCustomTipAmount('');
    setNewTipAmount(0);
    if (inputRef) inputRef.current.focus();
  }

  return <React.Fragment>{(newTipAmount !== null) &&

    <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">

      {/* Preset Tips */}

      {(!customTipEntry && itemsCostInCents > 0) && <ButtonGroup variant="outlined">
        {tipPercentages.map(p => {
          const thisAmount = Math.round((itemsCostInCents / 100) * p);
          const buttonActive = (thisAmount === newTipAmount);
          const buttonVariant = buttonActive ? "contained" : "outlined";
          const textColor = buttonActive ? "#FFF" : "#000";
          return <Button key={p} variant={buttonVariant} sx={tipButtonStyle} onClick={() => setNewTipAmount(thisAmount)}>
            <Typography variant="body1" style={{ marginBottom: "-3px" }}>{p}%</Typography>
            <Typography variant="body2" color={textColor}>{moneyFormat(thisAmount)}</Typography>
          </Button>
        })}
        {customTipSet && <Button variant="contained" sx={tipButtonStyle} onClick={toggleCustomTip}>
          <Typography variant="body1" style={{ marginBottom: "-3px" }}>{customTipPercentage}%</Typography>
          <Typography variant="body2" color="#FFF">{moneyFormat(newTipAmount)}</Typography>
        </Button>}
        {!customTipSet && <Button sx={tipButtonStyle} onClick={toggleCustomTip}>
          <Typography variant="button">Custom</Typography>
        </Button>}
      </ButtonGroup>}

      {/* Zero Dollar Order Tips */}

      {(!customTipEntry && itemsCostInCents <= 0) && <ButtonGroup variant="outlined">
        {zeroDollarTips.map(thisAmount => {
          const buttonActive = (thisAmount === newTipAmount);
          const buttonVariant = buttonActive ? "contained" : "outlined";
          return <Button key={thisAmount} variant={buttonVariant} sx={tipButtonStyle} onClick={() => setNewTipAmount(thisAmount)}>
            <Typography variant="body1">{moneyFormat(thisAmount)}</Typography>
          </Button>
        })}
        {customTipSet && <Button variant="contained" sx={tipButtonStyle} onClick={toggleCustomTip}>
          <Typography variant="body1">{moneyFormat(newTipAmount)}</Typography>
        </Button>}
        {!customTipSet && <Button sx={tipButtonStyle} onClick={toggleCustomTip}>
          <Typography variant="button">Custom</Typography>
        </Button>}
      </ButtonGroup>}

      {/* Custom Form */}

      {customTipEntry && <Stack direction="row" spacing={1}>
        <Button variant="outlined" color="error" onClick={toggleCustomTip}><CancelIcon /></Button>
        <TextField
          inputRef={inputRef}
          label="Custom Tip"
          variant="outlined"
          value={customTipAmount}
          onChange={changeCustomTip}
          sx={{ width: "180px" }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: <IconButton onClick={clearCustomTip}><CancelIcon /></IconButton>
          }}
        />
        <Button variant="contained" color="success" onClick={confirmCustomTip}><CheckCircleIcon /></Button>
      </Stack>}

    </Box>

  }</React.Fragment>

}

export default Tip;