import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../Components/Loading';
import ToastLayout from './Layout';
import Pay from './Pay';
import Paid from './Paid';
import Cancelled from './Cancelled';
import { useGetOrderByCodeQuery } from '../../Services/Toast';
import { transitionDuration } from '../../Constants';

export default function Order() {

  const { orderCode: orderParam } = useParams();
  const [orderCode, setOrderCode] = useState("");
  useEffect(() => { if (!!orderParam) setOrderCode(orderParam); }, [orderParam]);
  const { data: orderData = null, isLoading: orderLoading } = useGetOrderByCodeQuery(orderCode, { skip: (!!!orderCode) });

  let status = (orderData) ? orderData.status : "loading";
  if (status === "paid" || status === "close") status = "closed";
  if (!["loading", "open", "closed", "cancel"].includes(status)) status = "unknown";

  return <ToastLayout>
    {(orderLoading || status === "loading")
      ? <Loading headerText="Loading Order" transitionDuration={transitionDuration} />
      : <React.Fragment>
        {(orderData && status === "open") && <Pay order={orderData} />}
        {(orderData && status === "closed") && <Paid order={orderData} />}
        {(orderData && status === "cancel") && <Cancelled order={orderData} />}
        {(!orderData || status === "unknown") && <p>Unknown Status</p>}
      </React.Fragment>
    }
  </ToastLayout>

}