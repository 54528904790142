import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CreditCard, { Focused as FocusedType } from 'react-credit-cards';
import InputMask from 'react-input-mask';
import { useLazyGetEncryptedDataQuery } from '../../Services/Encryption';
import { useSubmitCreditCardPaymentMutation } from '../../Services/Toast';
import HelperText from '../../Components/HelperText';
import { moneyFormat } from '../../Helpers/Money';
import { CreditCard as CreditCardType, CreditCardPayment } from '../../Types/Toast';
import { defaultCardHolderName as defaultName, defaultCardHolderEmail as defaultEmail } from '../../Constants';

import "react-credit-cards/es/styles-compiled.css";
import './Cards.css';

const CardPay: FunctionComponent<{
  orderCode: string,
  totalInCents: number,
  tipInCents: number,
  handleCancel: () => void
}> = props => {

  const { orderCode, totalInCents, tipInCents, handleCancel } = props;
  const [cardNumber, setCardNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cvv, SetCvv] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [cardAccepted, setCardAccepted] = useState(false);
  const [cardDeclined, setCardDeclined] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [focusedField, setFocusedField] = useState("");
  const handleInputFocus = (e: React.ChangeEvent<HTMLDivElement>) => setFocusedField(e.target.id);
  useEffect(() => setCardName(`${firstName.trim()} ${lastName.trim()}`), [firstName, lastName]);

  // Lazy query data encryptor:
  const [cardData, setCardData] = useState<CreditCardType | null>(null);
  const [encryptedData, setEncryptedData] = useState<string | null>(null);
  const [getEncryptedData, { data: encryptedResponse = null, isLoading: isEncrypting }] = useLazyGetEncryptedDataQuery();
  useEffect(() => { if (!!cardData) getEncryptedData(cardData); }, [getEncryptedData, cardData]);
  useEffect(() => { if (encryptedResponse) setEncryptedData(encryptedResponse); }, [encryptedResponse]);

  // Encrypt data (on a 1 second delay) when all fields are filled:
  const refTimer = useRef<number | null>(null);
  const startTimer = () => {
    if (refTimer.current !== null) return;
    refTimer.current = window.setTimeout(() => {
      if (!!cardNumber && !!zipCode && !!cvv && !!cardExpiration) {
        const [expMonth, expYear] = cardExpiration.split("/");
        setCardData({
          cardNumber: cardNumber.replace(/\s/g, ""),
          zipCode,
          cvv: cvv.trim(),
          expMonth,
          expYear,
          "country": "USA"
        });
      }
    }, 1000);
  };
  const stopTimer = () => {
    if (refTimer.current === null) return;
    window.clearTimeout(refTimer.current);
    refTimer.current = null;
  };
  const resetTimer = () => { setEncryptedData(null); stopTimer(); startTimer(); }

  useEffect(() => { // Trigger
    if (!!cardNumber.trim() && !!zipCode.trim() && !!cvv.trim() && !!cardExpiration.trim()) resetTimer();
    else { setCardData(null); setEncryptedData(null); }
  }, [cardNumber, zipCode, cvv, cardExpiration]); // eslint-disable-line react-hooks/exhaustive-deps

  const cleanUp = () => {
    setCardAccepted(false);
    setCardDeclined(false);
    setFieldsDisabled(false);
  }

  useEffect(() => { // Cleanup
    cleanUp();
    return () => { if (refTimer.current !== null) window.clearTimeout(refTimer.current); };
  }, []);

  // Payment handler:
  const [submitCreditCardPayment, { isLoading: submittingPayment }] = useSubmitCreditCardPaymentMutation();

  // Enable pay button if we have all parts of the payload:
  const [payEnabled, setPayEnabled] = useState(false);
  useEffect(() => {
    setPayEnabled(
      (!!firstName && !!lastName)
      && !!encryptedData
      && !isEncrypting
      && !submittingPayment
      && !fieldsDisabled
      && totalInCents > 0
    );
  }, [firstName, lastName, encryptedData, isEncrypting, totalInCents, submittingPayment, fieldsDisabled]);

  // Payment submission:
  const handlePayment = async () => {
    setFieldsDisabled(true);
    if (encryptedData) {
      const payload: CreditCardPayment = {
        orderCode,
        tipInCents,
        creditCardData: encryptedData,
        cardFirst6: cardData ? cardData.cardNumber.slice(0, 6) : "",
        cardLast4: cardData ? cardData.cardNumber.slice(-4) : "",
        applicationData: null,
        name: !!cardName ? cardName : defaultName,
        email: !!email ? email : defaultEmail,
        postalCode: zipCode,
        address1: "",
        city: "",
        state: ""
      }
      await submitCreditCardPayment(payload)
        .unwrap()
        .then(() => setCardAccepted(true))
        .catch((err) => {
          console.error(err);
          setCardDeclined(true);
          setFieldsDisabled(false);
        });
    }
  }

  return <Grid container spacing={2} style={{ maxWidth: "600px" }}>

    <Grid item xs={12}>
      <CreditCard
        locale={{ valid: "Valid Until" }}
        placeholders={{ name: defaultName }}
        number={cardNumber}
        name={cardName}
        expiry={cardExpiration}
        cvc={cvv}
        focused={focusedField as FocusedType}
      // callback={console.log}
      />
    </Grid>

    <Grid item xs={12}>
      <Typography component="h4" variant="caption" align="center" className="helperAsterisk">
        <span>*</span> Required Fields {'\u00A0'}
      </Typography>
    </Grid>

    <Grid item xs={4}>
      <TextField
        id="first_name"
        required
        fullWidth
        label="First Name"
        value={firstName}
        autoComplete="cc-given-name"
        onChange={(e) => setFirstName(e.target.value)}
        onFocusCapture={handleInputFocus}
        disabled={fieldsDisabled}
      />
    </Grid>

    <Grid item xs={4}>
      <TextField
        id="last_name"
        required
        fullWidth
        label="Last Name"
        value={lastName}
        autoComplete="cc-family-name"
        onChange={(e) => setLastName(e.target.value)}
        onFocusCapture={handleInputFocus}
        disabled={fieldsDisabled}
      />
    </Grid>

    <Grid item xs={4}>
      <TextField
        id="email"
        fullWidth
        label="Email"
        type="email"
        value={email}
        autoComplete="email"
        onChange={(e) => setEmail(e.target.value)}
        disabled={fieldsDisabled}
      />
    </Grid>

    <Grid item xs={12}>
      <InputMask
        mask="9999 9999 9999 9999"
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
        maskChar=" "
        disabled={fieldsDisabled}
      >{() => <TextField
        id="number"
        required
        fullWidth
        label="Credit Card Number"
        autoComplete="cc-number"
        onFocusCapture={handleInputFocus}
        disabled={fieldsDisabled}
      />}</InputMask>
    </Grid>

    <Grid item xs={12 / 3}>
      <InputMask
        mask="99/99"
        value={cardExpiration}
        onChange={(e) => setCardExpiration(e.target.value)}
        maskChar=" "
        disabled={fieldsDisabled}
      >{() => <TextField
        id="expiry"
        required
        fullWidth
        label="Expiration"
        autoComplete="cc-exp"
        onFocusCapture={handleInputFocus}
        disabled={fieldsDisabled}
      />}</InputMask>
    </Grid>

    <Grid item xs={12 / 3}>
      <InputMask
        mask="9999"
        value={cvv}
        onChange={(e) => SetCvv(e.target.value)}
        maskChar=" "
        disabled={fieldsDisabled}
      >{() => <TextField
        id="cvc"
        required
        fullWidth
        label="CVC"
        onFocusCapture={handleInputFocus}
        disabled={fieldsDisabled}
      />}</InputMask>
    </Grid>

    <Grid item xs={12 / 3}>
      <InputMask
        mask="99999"
        value={zipCode}
        onChange={(e) => setZipCode(e.target.value)}
        maskChar=" "
        disabled={fieldsDisabled}
      >{() => <TextField
        id="zip"
        required
        fullWidth
        label="Zip Code"
        autoComplete="postal-code"
        disabled={fieldsDisabled}
      />}</InputMask>
    </Grid>

    <Grid item xs={12}>
      <Button fullWidth variant="contained" color="primary" size="large" onClick={handlePayment} disabled={!payEnabled}>
        Pay {moneyFormat(totalInCents)}
      </Button>
    </Grid>

    {cardAccepted && <Grid item xs={12}>
      <HelperText variant="success">
        Credit card payment successful! You will now be redirected...
      </HelperText>
    </Grid>}

    {cardDeclined && <Grid item xs={12}>
      <HelperText variant="error">
        Credit card payment declined. Please double-check the card information, or use a different card.
      </HelperText>
    </Grid>}

    <Grid item xs={12}>
      <Button fullWidth variant="outlined" color="primary" size="small" onClick={() => handleCancel()}>
        Cancel
      </Button>
    </Grid>

  </Grid>

}

export default CardPay;