import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { OrderState } from '../Types/Global';
import type { CheckSession, CheckTipUpdate } from '../Types/Stripe';
import type { OrderSession, OrderTipUpdate } from '../Types/Stripe';

const reducerPath = "stripeApi";
const { REACT_APP_STRIPE_API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_STRIPE_API_URL,
    // prepareHeaders: async (headers) => {
    //     const authResult = await getToken()
    //     if (authResult && authResult.accessToken) headers.set('Authorization', `Bearer ${authResult.accessToken}`)
    //     return headers;
    // }
});

export const stripeApi = createApi({
    reducerPath,
    tagTypes: ["Check", "Order"],
    baseQuery,
    endpoints: (builder) => ({
        getCheckById: builder.query<OrderState, string>({
            providesTags: ["Check"],
            query: (checkId) => `/api/check/${checkId}`
        }),
        updateCheckTip: builder.mutation<CheckSession, CheckTipUpdate>({
            invalidatesTags: ["Check"],
            query: ({ checkIdentifier, tipInCents }) => ({
                method: 'PUT',
                url: `/api/check`,
                body: { checkIdentifier, tipInCents }
            })
        }),
        getOrderByCode: builder.query<OrderState, string>({
            providesTags: ["Order"],
            query: (orderCode) => `/api/order/${orderCode}`
        }),
        updateOrderTip: builder.mutation<OrderSession, OrderTipUpdate>({
            invalidatesTags: ["Order"],
            query: ({ orderCode, tipInCents }) => ({
                method: 'PUT',
                url: `/api/order`,
                body: { orderCode, tipInCents }
            })
        }),
        orderSuccess: builder.mutation<OrderSession, string>({
            invalidatesTags: ["Order"],
            query: (sessionId) => ({
                method: 'PUT',
                url: `/api/session/success`,
                body: { sessionId }
            })
        }),
        orderCancel: builder.mutation<OrderSession, string>({
            invalidatesTags: ["Order"],
            query: (sessionId) => ({
                method: 'PUT',
                url: `/api/session/cancel`,
                body: { sessionId }
            })
        })
    })
});

export const {
    useGetCheckByIdQuery,
    useUpdateCheckTipMutation,
    useGetOrderByCodeQuery,
    useUpdateOrderTipMutation,
    useOrderSuccessMutation,
    useOrderCancelMutation
} = stripeApi;