import { DeliveryAddress as ToastDeliveryAddress } from '../Types/Toast';
import { getInitDeliveryAddress } from '../Constants';

export const addressParse = (addressObject: any) => {
    const parsedAddress: any = {};
    if (addressObject && addressObject.address_components) {
        Object.values(addressObject.address_components).forEach((obj: any) => {
            if (obj && obj.types) obj.types.forEach((addressPart: string) => {
                parsedAddress[addressPart] = obj.short_name;
            });
        });
    }
    return parsedAddress;
}

export const addressForToast = (addressObject: any) => {

    const finalizedAddress: ToastDeliveryAddress = getInitDeliveryAddress();
    const {
        street_number,
        route,
        locality,
        administrative_area_level_1,
        postal_code
    } = addressParse(addressObject);

    finalizedAddress.address = `${street_number} ${route}`;
    finalizedAddress.city = locality;
    finalizedAddress.state = administrative_area_level_1;
    finalizedAddress.zip = postal_code;
    return finalizedAddress;

}